import create, { GetState, SetState } from "zustand";

import cursorSlice, { CursorStateType } from "./cursor";
import menuSlice, { MenuStateType } from "./menu";

type StateFromFunctions<T extends [...any]> = T extends [infer F, ...infer R]
	? F extends (...args: any) => object
		? StateFromFunctions<R> & ReturnType<F>
		: unknown
	: unknown;

export type StoreSlice<T extends object, E extends object = T> = (
	set: SetState<E extends T ? E : E & T>,
	get: GetState<E extends T ? E : E & T>
) => T;

export type StoreType = StateFromFunctions<[typeof cursorSlice, typeof menuSlice]>;

const useStore = create<StoreType>((set, get) => ({
	...cursorSlice(
		set as unknown as SetState<CursorStateType>,
		get as GetState<CursorStateType>
	),
	...menuSlice(set as unknown as SetState<MenuStateType>, get as GetState<MenuStateType>),
}));

export default useStore;
