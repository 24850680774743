import { GetState, SetState } from "zustand";
import { StoreType, StoreSlice } from "../index";

type Cursor = {
	enlarged: boolean;
	inner?: string;
};
export interface CursorStateType {
	cursorState: Cursor;
	setCursorState: (arg: Cursor) => void;
}

const cursorSlice: StoreSlice<CursorStateType> = (
	set: SetState<CursorStateType>,
	get: GetState<CursorStateType>
) => ({
	cursorState: { enlarged: false, inner: "" },
	setCursorState: (arg: Cursor) => {
		set({ cursorState: { enlarged: arg.enlarged, inner: arg.inner } });
	},
});

export default cursorSlice;
