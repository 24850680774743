import { useRef, useEffect } from "react";
import useIsomorphicLayoutEffect from "../../hooks/useIsomorphicLayoutEffect";
import styles from "./customCursor.module.scss";
import useStore from "../../../store";
import { motion } from "framer-motion";

const Customcursor = () => {
	const mainCursor = useRef<HTMLDivElement>(null);
	const secondaryCursor = useRef<HTMLDivElement>(null);

	const positionRef = useRef<any>({
		mouseX: 0,
		mouseY: 0,
		destinationX: 0,
		destinationY: 0,
		distanceX: 0,
		distanceY: 0,
		key: -1,
	});

	useIsomorphicLayoutEffect(() => {
		document.addEventListener("mousemove", (event) => {
			const { clientX, clientY } = event;
			if (mainCursor.current !== null) {
				const mouseX = clientX - mainCursor?.current?.clientWidth / 2;
				const mouseY = clientY - mainCursor?.current?.clientHeight / 2;

				if (positionRef.current && secondaryCursor.current) {
					positionRef.current.mouseX = mouseX - secondaryCursor?.current?.clientWidth / 2;
					positionRef.current.mouseY =
						mouseY - secondaryCursor?.current?.clientHeight / 2;
				}

				if (typeof window !== "undefined" && mainCursor.current) {
					mainCursor.current.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
				}
			}
		});

		return () => {};
	}, []);

	useEffect(() => {
		const followMouse = () => {
			if (positionRef.current) {
				positionRef.current.key = requestAnimationFrame(followMouse);
			}

			const { mouseX, mouseY, destinationX, destinationY, distanceX, distanceY } =
				positionRef?.current;

			if (!destinationX || !destinationY) {
				positionRef.current.destinationX = mouseX;
				positionRef.current.destinationY = mouseY;
			} else {
				positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
				positionRef.current.distanceY = (mouseY - destinationY) * 0.1;

				if (
					Math.abs(positionRef?.current?.distanceX) +
						Math.abs(positionRef?.current?.distanceY) <
					0.1
				) {
					positionRef.current.destinationX = mouseX;
					positionRef.current.destinationY = mouseY;
				} else {
					positionRef.current.destinationX += distanceX;
					positionRef.current.destinationY += distanceY;
				}
			}

			if (
				typeof window !== "undefined" &&
				document.getElementById("secondaryCursor") &&
				secondaryCursor.current
			) {
				secondaryCursor.current.style.transform = `translate3d(${destinationX + 4}px, ${
					destinationY + 4
				}px, 0)`;
			}
		};

		followMouse();
	}, []);

	const cursorState = useStore((state) => state.cursorState);

	return (
		<div>
			<motion.div
				ref={secondaryCursor}
				id="secondaryCursor"
				style={{ transform: "translate3d(0,0,0)" }}
				className={`
        ${styles["secondary-cursor"]}
        ${cursorState.enlarged ? styles["enlarged"] : ""}`}
			>
				{cursorState.inner}
			</motion.div>
			<div
				ref={mainCursor}
				style={{ transform: "translate3d(0,0,0)" }}
				className={styles["main-cursor"]}
			></div>
		</div>
	);
};

export default Customcursor;
