import { GetState, SetState } from "zustand";
import { StoreSlice } from "../index";

type ShowMenu = {
	show: boolean;
};

export interface MenuStateType {
	menuState: ShowMenu;
	setMenuState: (arg: ShowMenu) => void;
}

const menuSlice: StoreSlice<MenuStateType> = (
	set: SetState<MenuStateType>,
	get: GetState<MenuStateType>
) => ({
	menuState: { show: false },
	setMenuState: (arg: ShowMenu) => {
		set({ menuState: { show: arg.show } });
	},
});

export default menuSlice;
