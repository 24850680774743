import type { AppProps } from "next/app";
import "../src/styles/base/_reset.scss";
import "../src/styles/styles.scss";
import Customcursor from "../src/components/custom-cursor";
import { AnimatePresence } from "framer-motion";

function MyApp({ Component, pageProps, router }: AppProps) {

	return (
		<>
			<Customcursor />
			<AnimatePresence exitBeforeEnter>
					<Component {...pageProps} key={router.route} />
			</AnimatePresence>
		</>
	);
}

export default MyApp;
